
import React from 'react';
import { Text, Image, RawHtml, Button } from 'ui';
import NextLink from 'next/link';
import { breakpoints } from '~/constants';
import { CmsModel } from 'api-types';
import styles from './Article.module.css';

export type ArticleProps = CmsModel.MediaAndText;


export const Article = ({ headline, image, description, callToAction, }: ArticleProps) => {
    const hrefLink = callToAction?.url || ''
    return (
        <NextLink href={hrefLink} passHref>
            <a className={styles.content}
                draggable={false}
                target={callToAction?.target}
                title={callToAction?.title}
            >
                <div className={styles.article} >
                    {image ? (
                        <Image
                            alt={headline}
                            {...image}
                            height={300}
                            width={340}
                            objectFit="cover"
                            sizes={`(min-width: ${breakpoints.md}px) 340px, 230px`}
                            draggable={false}

                        />
                    ) : null}
                    {headline ? <Text variant="display5" as='h3' children={headline} /> : null}
                    {description ? (
                        <RawHtml html={description} />
                    ) : (
                        null
                    )}

                    {callToAction ? (
                        <Button
                            iconPosition="right"
                            iconName='arrow-right'
                            variant={'link'}
                        >
                            {callToAction?.title}
                        </Button>
                    ) : null}
                </div>
            </a>
        </NextLink>
    );
};